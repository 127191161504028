import type { LoaderFunctionArgs } from "@remix-run/node";
import { redirect } from "@remix-run/node";
import { getAuthAccount } from "~/libs/account/authentication";

import { Benefits } from "./Benefits";
import { Cta } from "./Cta";
import { Faq } from "./Faq";
import { Footer } from "./Footer";
import { Hero } from "./Hero";
import { Logos } from "./Logos";
import { Testimonials } from "./Testimonials";

export async function loader({ request }: LoaderFunctionArgs) {
  const account = await getAuthAccount(request);
  if (account) return redirect(`/my/cases`);

  return null;
}

export default function IndexPage() {
  return (
    <div>
      <Hero />
      <Benefits />
      <Cta />
      <Testimonials />
      <Logos />
      <Faq />
      <Footer />
    </div>
  );
}
